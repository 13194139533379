const newseLatterData = [
  {
    id: '1',
    icon: {
      parentBg: '#beaaf7',
      childBg: 'rgb(131 88 255) ',
      svg: 'icon-wallet',
    },

    title: 'Join & Discover',
    text: 'Log in for full history access and exclusive premium features.',
  },
  {
    id: '2',
    icon: {
      parentBg: '#c4f2e3',
      childBg: 'rgb(16 185 129)',
      svg: 'icon-wallet',
    },

    title: 'Choose Your Detector',
    text: 'Select from models tailored for text, code, images, or videos, including Llama/ChatGPT-specific detection.',
  },
  {
    id: '3',
    icon: {
      parentBg: '#cddffb',
      childBg: 'rgb(66 138 248)',
      svg: 'icon-gallery',
    },
    title: 'Instant Insights',
    text: 'Submit your content and receive a detailed analysis report within seconds.',
  },
  {
    id: '4',
    icon: { parentBg: '#ffd0d0', childBg: 'rgb(239 68 68)', svg: 'icon-list' },
    title: 'Access History Anytime',
    text: 'Your analyses are saved, allowing for easy retrieval and review at any moment.',
  },
]

export { newseLatterData }
